import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import * as styles from './styles.module.scss';

export default class Hero extends React.Component {
  render() {
    const imgClassList = [styles.img];

    this.props.darkened && imgClassList.push(styles.darkened);
    this.props.darkenedEdges && imgClassList.push(styles.darkenedEdges);

    return (
      <div className={styles.root} id={this.props.id}>
        <div className={imgClassList.join(' ')}>
          <GatsbyImage
              image={this.props.img}
              alt={"The joy of music"}
          />
        </div>
        {this.props.children}
      </div>
    );
  }
}
