// extracted by mini-css-extract-plugin
export var root = "styles-module--root--1A-hs";
export var hero = "styles-module--hero--A1Qaf";
export var heroContent = "styles-module--heroContent--lc436";
export var name = "styles-module--name--L4MmJ";
export var svg = "styles-module--svg--32B4A";
export var nonWispy = "styles-module--nonWispy--2f7r_";
export var wispy = "styles-module--wispy--26M0P";
export var tagline = "styles-module--tagline--XfkV5";
export var content = "styles-module--content--_qNcI";
export var header = "styles-module--header--ncWXa";
export var about = "styles-module--about--3VfWQ";
export var pitch = "styles-module--pitch--22-84";
export var eventName = "styles-module--eventName--AiCIf";
export var mission = "styles-module--mission--19ImV";
export var classes = "styles-module--classes--33oLR";
export var events = "styles-module--events--GkiOk";
export var action = "styles-module--action--2acro";
export var img = "styles-module--img--1mgmB";