import React from 'react';
import { graphql } from 'gatsby'
import LandingPage from 'components/Landing';
import Layout from 'components/Layout'

export default class IndexPage extends React.Component {
  render() {
    const { heroImg, stdntImg, violinImg, groupImg } = this.props.data;
    const isLoginPage = /\/login\/?$/i.test(this.props.location.pathname)
    return (
      <Layout
        location={this.props.location}
        title={"The Studio for Aspiring Violinists | Stonybrook Strings"}
        description={"A supportive and holistic environment for young musicians to explore and achieve their potential. Join today!"}
        loginModalInitiallyOpen={isLoginPage}
      >
        <LandingPage
          heroImg={heroImg.childImageSharp.gatsbyImageData}
          missionImg={violinImg.childImageSharp.gatsbyImageData}
          classesImg={stdntImg.childImageSharp.gatsbyImageData}
          eventsImg={groupImg.childImageSharp.gatsbyImageData}
        />
      </Layout>
    );
  }
}

export const query = graphql`
  query IndexPageQuery {
    heroImg: file(relativePath: { eq: "cheeky.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    stdntImg: file(relativePath: { eq: "stdnt.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 600, height: 500)
      }
    }

    violinImg: file(relativePath: { eq: "smiles-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 600, height: 500)
      }
    }

    groupImg: file(relativePath: { eq: "lawn-games.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 600, height: 500)
      }
    }
  }
`;
