import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image"
import Link from 'gatsby-link';

import Hero from 'components/Hero';
import Button from 'components/Button';
// import SubscriptionForm from 'components/Subscribe';
import { getSortedEvents } from 'utils/events';
import * as styles from './styles.module.scss';
import moment from 'moment';
import Logo from 'components/Logo/Tall';
import LogoWispy from 'components/Logo/TallWispy';

export default class LandingPage extends React.Component {
 render() {
    const events = getSortedEvents(),
      nextEvent = events && events[0];
    let eventSectionText;

    if (nextEvent) {
      const nextEventName = nextEvent.name,
        nextEventDate = moment(nextEvent.date, 'YYYY-MM-DD HH:mm'),
        nextEventDateStr = nextEventDate.format('MMMM D');

      eventSectionText = (<div>
        Our <span className={styles.eventName}>"{nextEventName}"</span> is coming up on {nextEventDateStr}. Join us!
      </div>);
    } else {
      eventSectionText = (<div>
        We host events year-round, for our musicians and for their parents. Join us for one!
      </div>);
    }

    return (
      <div className={styles.root}>
        <Hero id={styles.hero} img={this.props.heroImg} darkenedEdges={true}>
          <div className={styles.heroContent}>
            <LogoWispy className={[styles.svg, styles.wispy].join(" ")} />
            <Logo className={[styles.svg, styles.nonWispy].join(" ")} />
          </div>
        </Hero>

        <div className={styles.content}>
          <div className={styles.about}>
            <h1 className={styles.header}>A studio for aspiring violinists</h1>
            <div className={styles.pitch}>
              A supportive and holistic environment for young musicians to explore and achieve their
              potential. Give them a great start. Apply now!
            </div>
            <Button to="/contact/" text="Get in touch" />
          </div>

          <Link to="/about/" className={styles.mission}>
            <GatsbyImage className={styles.img} image={this.props.missionImg} alt={"About Us"}/>
            <h5 className={styles.header}>About Us</h5>
            <div>
              A studio of talented and committed musicians stems from a well-crafted and diverse
              curriculum.
            </div>
            <div className={styles.action}>Learn more →</div>
          </Link>

          <Link to="/classes/" className={styles.classes}>
            <GatsbyImage className={styles.img} image={this.props.classesImg} alt={"Classes"}/>
            <h5 className={styles.header}>Our Classes</h5>
            <div>
              From weekly lessons and group classes to practice coaching and music theory, students
              are always engaged and supported in their learning.
            </div>
            <div className={styles.action}>Find a class →</div>
          </Link>

          <Link to="/events/" className={styles.events}>
            <GatsbyImage className={styles.img} image={this.props.eventsImg} alt={"Events"}/>
            <h5 className={styles.header}>Events</h5>
            {eventSectionText}
            <div className={styles.action}>See all upcoming events →</div>
          </Link>
        </div>

        {/*<SubscriptionForm text="Sign-up for the Stonybrook Strings newsletter:" />*/}
      </div>
    );
  }
}