import React from 'react';
import Link from 'gatsby-link';
import Spinner from 'components/Spinner';
import * as styles from './styles.module.scss';

export default class Button extends React.Component {
  render() {
    const isLink = !!this.props.to,
      className = `${styles.root} ${this.props.className}`,
      btn = (
        <button>
          <span>{ this.props.withSpinner ? <Spinner /> : this.props.text}</span>
        </button>
      );

    return isLink ? (
      <Link to={this.props.to} className={className}>
        {btn}
      </Link>
    ) : (
      <div className={className}>{btn}</div>
    );
  }
}
